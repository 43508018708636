export const visitorInfoCache = {}
let visitorInfoPromise = null

export async function getVisitorInfo(skipCache = false) {
  const cacheKey = 'visitorInfo'

  if (!skipCache && visitorInfoCache[cacheKey]) {
    return visitorInfoCache[cacheKey]
  }

  if (visitorInfoPromise) {
    return visitorInfoPromise
  }

  visitorInfoPromise = fetch('/relevanceapi/visitorinfo', {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then(data => {
      visitorInfoCache[cacheKey] = data
      visitorInfoPromise = null
      return data
    })
    .catch(error => {
      console.error(error)
      visitorInfoPromise = null
      throw error
    })

  return visitorInfoPromise
}
