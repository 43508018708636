import React from 'react'
import { BrandIconId } from './BrandIconId.type'
import { BrandIconColor } from './BrandIconColor.type'
import { BrandIconSize } from './BrandIconSize.type'

import './style.module.scss'

declare global {
  interface Window {
    AMPCOMPATH: string
  }
}

export type BrandIconProps = {
  icon: BrandIconId
  color?: BrandIconColor
  size?: BrandIconSize
  fill?: boolean
  className?: string
  style?: React.CSSProperties
  ariaLabel?: string
  spriteUrl?: string
  testId?: string
}

export const BrandIcon = (props: BrandIconProps) => {
  const {
    icon,
    size,
    color,
    fill,
    style,
    className,
    ariaLabel,
    spriteUrl,
    testId
  } = props

  const classList = ['BrandIcon']
  if (className) {
    classList.push(className)
  }

  // TODO flesh out colors and fill
  if (fill) {
    classList.push('u-fillColorBrand')
  }

  switch (color) {
    case BrandIconColor.BERRY:
      classList.push('u-bgColorBerry u-fillColorWhite')
      break
    case BrandIconColor.PURPLE:
      classList.push('u-bgColorPurple u-fillColorWhite')
      break
    case BrandIconColor.MIDNIGHT_BLUE:
      classList.push('u-bgColorMidnightBlue u-fillColorWhite')
      break
    default:
      break
  }

  switch (size) {
    case BrandIconSize.SMALL:
      classList.push('BrandIcon--sizeSmall')
      break
    case BrandIconSize.LARGE:
      classList.push('BrandIcon--sizeLarge')
      break
    case BrandIconSize.MEDIUM:
      classList.push('BrandIcon--sizeMedium')
      break
    case BrandIconSize.DEFAULT:
      classList.push('BrandIcon--sizeDefault')
      break
    default:
      break
  }

  const spriteHref = () => {
    if (spriteUrl === '') {
      return `#${icon}`
    }
    if (spriteUrl) {
      return `${spriteUrl}#${icon}`
    }
    return `${window.AMPCOMPATH}images/brand-icons/icon-sprite.svg#${icon}`
  }

  return (
    <svg
      className={classList.join(' ')}
      aria-hidden="true"
      preserveAspectRatio="xMinYMin meet"
      style={style}
      data-testid={testId ? `${testId}-svg` : null}
      aria-label={ariaLabel}
    >
      <use
        href={spriteHref()}
        data-testid={testId ? `${testId}-svg-use` : null}
      />
    </svg>
  )
}

export default BrandIcon
