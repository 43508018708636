export enum BrandIconId {
  ACHIEVING = 'achieving',
  BALANCE = 'balance',
  BELL = 'bell',
  BITCOIN = 'bitcoin',
  BUSINESS_MAN = 'business-man',
  BUSINESS_WOMAN = 'business-woman',
  CALCULATOR = 'calculator',
  CALENDAR_DAY = 'calendar-day',
  CALENDAR_MONTH = 'calendar-month',
  CAPITOL_BUILDING = 'capitol-building',
  CHECKMARK = 'checkmark',
  CLOCK = 'clock',
  COINS = 'coins',
  COLLABORATION = 'collaboration',
  COMMODITIES = 'commodities',
  COMMUNITY = 'community',
  CONVERSATION = 'conversation',
  CREATIVE = 'creative',
  CREDENTIAL_STAR = 'credential-star',
  CREDIT_CARD = 'credit-card',
  CULTURE = 'culture',
  DIVERSIFY = 'diversify',
  DOCUMENT = 'document',
  E_DELIVERY_1 = 'e-delivery-1',
  E_DELIVERY_2 = 'e-delivery-2',
  EDUCATION = 'education',
  EMAIL = 'email',
  EXPENSES = 'expenses',
  FAMILY = 'family',
  GEARS = 'gears',
  GLOBAL_IMPACT = 'global-impact',
  HANDSHAKE = 'handshake',
  HEALTH = 'health',
  HOME = 'home',
  IDEA = 'idea',
  KEY = 'key',
  LAPTOP = 'laptop',
  LINE_CHART = 'line-chart',
  LOCATION = 'location',
  MAN = 'man',
  MEAL = 'meal',
  MICROPHONE = 'microphone',
  MOBILE_DEVICE = 'mobile-device',
  MOBILE_DEVICE_TOUCH = 'mobile-device-touch',
  MONITOR = 'monitor',
  NETWORK = 'network',
  OFFICE = 'office',
  OTHER = 'other',
  PAPER_STACK = 'paper-stack',
  PAYCHECK = 'paycheck',
  PEACE_MIND = 'peace-mind',
  PERSON = 'person',
  PHONE = 'phone',
  PLAN = 'plan',
  PLEASURE = 'pleasure',
  PRIORITIZE = 'prioritize',
  PROTECTION = 'protection',
  RETIREMENT = 'retirement',
  REVIEW = 'review',
  REWARDS = 'rewards',
  SAVINGS = 'savings',
  SEARCH = 'search',
  SECURITY = 'security',
  SIGN = 'sign',
  SMALL_BUSINESS = 'small-business',
  SPLIT_PIE_CHART = 'split-pie-chart',
  SUSTAINABILITY = 'sustainability',
  TARGET = 'target',
  TOOLS = 'tools',
  TRANSFERRING_FUNDS = 'transferring-funds',
  TRANSPORT = 'transport',
  TRAVEL = 'travel',
  TROPHY = 'trophy',
  VIDEO = 'video',
  WEDDING = 'wedding',
  WIFI = 'wifi',
  WOMAN = 'woman',
  WORK = 'work',
  X_MARK = 'x-mark',
  AMPF_LOGO = 'ampf-logo',
  AMPF_COMPASS = 'ampf-compass',
  SOCIAL_FACEBOOK = 'social-facebook',
  SOCIAL_FACEBOOK_WHITE = 'social-facebook-white',
  SOCIAL_INSTAGRAM = 'social-instagram',
  SOCIAL_LINKEDIN = 'social-linkedin',
  SOCIAL_LINKEDIN_WHITE = 'social-linkedin-white',
  SOCIAL_YOUTUBE = 'social-youtube',
  SOCIAL_YOUTUBE_ALMOST_BLACK = 'social-youtube-almost-black',
  SOCIAL_YOUTUBE_WHITE = 'social-youtube-white'
}
