import React from 'react'
import { createRoot } from 'react-dom/client'
import Navigation from './Navigation.jsx'

export default class AmeripriseFooterElement extends HTMLElement {
  connectedCallback() {
    this.mountPoint = document.createElement('div')
    this.menuApiPath = this.getAttribute('data-menu-api-path')
    this.menuName = this.getAttribute('data-menu-name')
    this.version = this.getAttribute('data-version')
    this.customerServiceHeading = this.getAttribute('data-customer-heading')
    this.customerServiceDescription = this.getAttribute(
      'data-customer-description'
    )
    this.customerServiceLink = this.getAttribute('data-customer-link')

    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(this.mountPoint)

    this.template = this.querySelector('#footer-template')
    if (this.template && this.template.content) {
      shadowRoot.appendChild(this.template.content)
    }

    const root = createRoot(this.mountPoint)

    // hide DOM during page load
    root.render(
      <div className="u-adel-reset">
        <div className="u-block" style={{ display: 'none' }}>
          <Navigation
            menuApiPath={this.menuApiPath}
            menuName={this.menuName}
            version={this.version}
            customerServiceHeading={this.customerServiceHeading}
            customerServiceDescription={this.customerServiceDescription}
            customerServiceLink={this.customerServiceLink}
          ></Navigation>
        </div>
      </div>
    )
  }
}
