import { push } from './_utils'

const returnJSONIfOK = res => {
  if (!res.ok) {
    const { host, pathname } = new URL(res.url)
    throw new Error(`Couldn't reach service: ${host + pathname}`)
  }

  return res.json()
}

export const fetchGeocodeFromGeoloc = json => {
  if (!window.GOOGLE_MAPS_CLIENT_KEY) {
    throw new Error('Google Maps client key undefined')
  }

  if (!json.location) {
    throw new Error(`The geolocation service failed`)
  }

  const googleGeocodeAPI = (lat, lng) =>
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${window.GOOGLE_MAPS_CLIENT_KEY}`

  return fetch(googleGeocodeAPI(json.location.lat, json.location.lng))
}

export const returnLocationFromGeocode = json => {
  if (!json || !json.results) {
    throw new Error(`The geocode service failed`)
  }

  const empty = { short_name: '', long_name: '' }
  const first = json.results[0].address_components
  const { short_name: number } =
    first.find(c => c.types.includes('street_number')) || empty
  const { long_name: route } =
    first.find(c => c.types.includes('route')) || empty
  const { short_name: city } =
    first.find(c => c.types.includes('locality')) || empty
  const { short_name: postalCode } =
    first.find(c => c.types.includes('postal_code')) || empty
  const { short_name: abbr, long_name: name } =
    first.find(c => c.types.includes('administrative_area_level_1')) || empty
  const { short_name: cAbbr, long_name: cName } =
    first.find(c => c.types.includes('country')) || empty
  const address = `${number} ${route}`
  const stateProvince = { abbr, name }
  const country = { abbr: cAbbr, name: cName }

  return { address, city, country, postalCode, stateProvince }
}

const googleLocation = async () => {
  if (!window.GOOGLE_MAPS_CLIENT_KEY) {
    throw new Error('Google Maps client key undefined')
  }

  const GOOGLE_LOCATION_API = `https://www.googleapis.com/geolocation/v1/geolocate?key=${window.GOOGLE_MAPS_CLIENT_KEY}`

  return fetch(GOOGLE_LOCATION_API, { method: 'POST' })
    .then(returnJSONIfOK)
    .then(fetchGeocodeFromGeoloc)
    .then(returnJSONIfOK)
    .then(returnLocationFromGeocode)
}

export async function location() {
  const result = { data: null, errors: [] }

  try {
    result.data = await googleLocation()
  } catch (error) {
    result.errors = push(result.errors, error)
  }

  return result
}

export default async function () {
  try {
    const { postalCode } = await googleLocation()
    return postalCode
  } catch (e) {
    console.error('Failed to get ZIP Code.', e)
    return ''
  }
}
